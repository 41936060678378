<template>
  <section class="container pt-10" v-scroll-reveal>
    <h1 class="display-3 mb-12 mt-2">
      404
    </h1>
    <p class="h3 mb-10">
      Ooopsie! You're lost...
    </p>
    <p class="mb-40 pb-2">
      The page you're looking for doesn't exist or has been moved.
    </p>
    <router-link to="/" class="button--kiiner">
      Back to home page
    </router-link>
  </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
